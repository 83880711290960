/*
 * @Author: gaylen
 * @Date: 2021-04-27 16:55:12
 * @LastEditTime: 2023-11-20 12:02:35
 * @LastEditors: gaylen it7@importgogo.com
 * @Description: 
 * @FilePath: \web_admin\src\router\index.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import { createRouter,createWebHashHistory } from 'vue-router'

import NProgress from 'nprogress'
import Layout from '../views/layout'


// const children=[...test]

const routes = [  
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/news/type',
    hidden: true,
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/index'),
      meta: { title: '首页', icon: 'el-icon-s-home' }
    }]
  },

  

  {
    path: '/linkURl',
    component: Layout,
    hidden: true,
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/friendLink/list'),
      meta: { title: '友情链接', icon: 'el-icon-s-home' }
    }]
  },


  


  {
    path: '/news/detail',
    component: () => import('@/views/news/list/detail'),
    hidden: true
  },
  
  {
    path: '/news',
    component: Layout,
    redirect: '/news/list', // 主路由重定向到新闻文章列表页
    name: 'news',
    meta: { title: '新闻管理', icon: 'el-icon-news' },
    children: [
      {
        path: 'type',
        name: 'newsType',
        keepAlive: true,
        component: () => import('../views/news/type/index'),
        meta: { title: '新闻分类'}
      },
      {
        path: 'list',
        name: 'newsList',
        keepAlive: true,
        component: () => import('../views/news/list/index'),
        meta: { title: '新闻文章' }
      },
      {
        path: 'create',
        hidden: true,
        name: 'createNews',
        keepAlive: false,
        component: () => import('../views/news/list/createNews'),
        meta: { title: '发布新闻' }
      }
    ]
  },

  {
    path: '/case',
    component: Layout,
    redirect: '/case',
    name: 'case',
    redirect: '/case/list',
    meta: { title: '案例管理', icon: 'el-icon-news' },
    children: [
      {
        path: 'type',
        name: 'caseType',
        keepAlive: true,
        component: () => import('../views/case/type/index'),
        meta: { title: '案例分类'}
      },
      {
        path: 'list',
        name: 'caseList',
        keepAlive: true,
        component: () => import('../views/case/list/index'),
        meta: { title: '案例列表'  }
      },
      {
        path: 'create',
        hidden: true,
        name: 'createcase',
        component: () => import('../views/case/list/createcase'),
        meta: { title: '创建案例'}
      }
      
    ]
  },
  {
    path: '/case/detail',
    component: () => import('@/views/case/list/detail'),
    hidden: true
  },

  {
    path: '/Advertising',
    component: Layout,
    redirect: '/Advertising/firstPage_banner',
    name: 'Advertising',
    meta: { title: '广告管理', icon: 'el-icon-postcard' },
    children: [
      {
        path: 'firstPage_banner',
        name: 'firstPage_banner',
        component: () => import('@/views/Advertising/firstPage_banner/index'),
        meta: { title: '首页轮播图' }
      },
      {
        path: 'createAdBanner',
        name: 'createAdBanner',
        hidden: true,
        component: () => import('@/views/Advertising/firstPage_banner/createAdBanner'),
        meta: { title: '新增轮播图' }
      },
      {
        path: 'editAdBanner',
        name: 'editAdBanner',
        hidden: true,
        component: () => import('@/views/Advertising/firstPage_banner/createAdBanner'),
        meta: { title: '修改轮播图' }
      },
      {
        path: 'routeList',
        name: 'routeList',
        hidden: true,
        component: () => import('@/views/Advertising/routeList/index'),
        meta: { title: '推荐路线'  }
      }
    ]
  },


  {
    path: '/company',
    component: Layout,
    redirect: '/company/contactUs',
    name: 'company',
    meta: { title: '联系我们', icon: 'el-icon-s-help' },
    children: [
      {
        path: 'contactUs',
        name: 'contactUs',
        component: () => import('@/views/aboutUs/contactUs/index'),
        meta: { title: '联系我们', icon: 'el-icon-phone' }
      },
      {
        path: 'createCompany',
        hidden: true,
        name: 'createCompany',
        component: () => import('../views/aboutUs/contactUs/create'),
        meta: { title: '新增分公司'}
      }
    ]
  },
  {
    path: '/product',
    component: Layout,
    hidden: true,
    redirect: '/product/productCase',
    name: 'product',
    meta: { title: '客户案例', icon: 'el-icon-warning' },
    children: [
      {
        path: 'productCase',
        name: 'productCase',
        component: () => import('@/views/aboutUs/productCase/index'),
        meta: { title: '客户案例', icon: 'el-icon-warning' }
      },
     
      {
        path: 'create',
        hidden: true,
        name: 'createproduct',
        component: () => import('../views/aboutUs/productCase/create'),
        meta: { title: '新建客户案例' ,noCache: true}
      }
    ]
  },

  // 友情链接先不上   等后台接口 
  // {
  //   path: '/linkURl',
  //   component: Layout,
  //   redirect: '/linkURl/list',
  //   name: 'linkURl',
  //   meta: { title: '友情链接', icon: 'el-icon-s-help' },
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'list',
  //       component: () => import('@/views/friendLink/index'),
  //       meta: { title: '友情链接', icon: 'el-icon-phone' }
  //     }
  //   ]
  // },
  
  {
    path: '/system',
    component: Layout,
    redirect: '/system/config',
    name: 'system',
    hidden: true,
    meta: { title: '系统功能', icon: 'el-icon-s-help' },
    children: [
      {
        path: 'config',
        name: 'config',
        component: () => import('@/views/system/config/index'),
        meta: { title: '系统设置', icon: 'el-icon-setting' }
      }
    ]
  }
  ]
  
  const router = createRouter({
    history: createWebHashHistory(),
    routes
  })
  // 路由守卫  判断是否登录  没有登录跳回登录页
  // TODO 登录接口还没调  这里先不做
  router.beforeEach((to,from) => {
    NProgress.start()
    if(to.path==="/login"){
      console.log(to,from)
    }else{
      // router.push(`/login?redirect=${to.fullPath}`)
      // console.log(to,from)
    }
  })
  
  
 export default router